<template>
  <div v-if="slides.length > 0" class="slider-wrapper">
    <transition name="slider-slide-in">
      <!-- eslint-disable-next-line vue/require-toggle-inside-transition -->
      <div class="slider-item" :class="{ pointer: linkForSlide(slide) }">
        <component
          :is="isUrlExternal(linkForSlide(slide)) ? 'a' : 'nuxt-link'"
          v-if="linkForSlide(slide)"
          :to="isUrlExternal(linkForSlide(slide)) ? null : linkForSlide(slide)"
          :target="isUrlExternal(linkForSlide(slide)) ? '_blank' : null"
          :href="linkForSlide(slide)"
          @click="slide.download_link ? trackDownloadLink() : null"
        >
          <ImageLazy :src="slide[imagePrefix]" :alt="slide.image_alt" class-variant="slider-img" />
        </component>
        <ImageLazy
          v-else
          :src="slide[imagePrefix]"
          :alt="slide.image_alt"
          class-variant="slider-img"
          :data-test-id="slide?.testId"
        />
      </div>
    </transition>
    <div v-if="slides.length > 1" class="dot-wrapper">
      <div
        v-for="(dot, i) in slides"
        :key="`dot-${i}`"
        class="dot"
        :class="{ active: activeSlide === i }"
        @click="activeSlide = i"
      />
    </div>
  </div>
</template>

<script>
import URL from '@/mixins/url-mixin'
import Mobile from '@/mixins/mobile-mixin'
import DownloadApp from '@/mixins/download-app-mixin'

export default {
  name: 'SliderIndex',
  mixins: [URL, Mobile, DownloadApp],
  props: {
    slides: { type: Array, default: () => [] },
    autoplay: { type: Boolean, default: false },
    autoplayDelay: { type: Number, default: 3000 },
  },
  data() {
    return {
      activeSlide: 0,
      interval: null,
    }
  },
  computed: {
    slide() {
      return this.slides[this.activeSlide]
    },
    imagePrefix() {
      // I hate this, but the carousel does not work with a srcset
      if (this.isMobile) return 'mobile_image'
      return 'wide_image'
    },
  },
  mounted() {
    if (this.autoplay && this.slides.length > 1) {
      this.interval = setInterval(() => this.updateActiveSlide(), this.autoplayDelay)
    }
  },
  beforeUnmount() {
    clearInterval(this.interval)
  },
  methods: {
    updateActiveSlide(i) {
      if (i && i < this.slides.length - 1) {
        this.activeSlide = i
      } else if (this.activeSlide < this.slides.length - 1) {
        this.activeSlide++
      } else {
        this.activeSlide = 0
      }
    },
    linkForSlide(slide) {
      if (slide.download_link) {
        // This option overrides potential target_value
        return this.downloadAppLink
      }
      return slide.target_value || slide.url
    },
  },
}
</script>

<style lang="scss">
.slider-wrapper {
  margin: 0;
  overflow: hidden;
  position: relative;
  width: 100%;

  .slider-item {
    justify-content: center;
    height: 100%;
    width: 100%;
    transition-duration: 0.5s;

    .slider-img {
      max-height: 400px;
      width: 100%;
    }
  }

  .dot-wrapper {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: $spacing-md;
    width: 100%;
    z-index: 1;
    .dot {
      cursor: pointer;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background-color: var(--bg-grey);
      margin: 0 5px;
      &.active {
        background-color: var(--dark-blue);
      }
    }
  }
}

.slider-slide-in-enter-active,
.slider-slide-in-leave-active {
  transition: transform 0.3s ease;
}
.slider-slide-in-enter-from,
.slider-slide-in-leave-to {
  transform: translateX(100vw);
}
</style>
